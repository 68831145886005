html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 16px;
}

img {
  width: 100%;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.font-joti {
  font-family: "Joti One", cursive;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 0 24px;
  @media screen and (max-width: 1500px) {
    width: 95%;
    padding: 0;
  }
}

.App {
  overflow: hidden;
}

.section-title {
  position: relative;
  z-index: 999;
  width: 350px;
  margin: -185px auto 30px;
  @media screen and (max-width: 1500px) {
    width: 300px;
    margin: -173px auto 30px;
  }
}

.header {
  padding: 20px 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1300px) {
      flex-direction: column;
      gap: 30px;
    }
    @media screen and (max-width: 600px) {
      gap: 25px;
    }
  }
  &-menu {
    display: flex;
    gap: 40px;
    font-size: 18px;
    @media screen and (max-width: 600px) {
      gap: 25px;
      font-size: 16px;
    }
  }
  &-action {
    display: flex;
    align-items: center;
    gap: 80px;
    &-social {
      display: flex;
      align-items: center;
      gap: 30px;
    }
    &-buy {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }
}

.intro {
  background-color: #faab35;
  background-image: url("./assets/img/bg-main.svg");
  background-size: cover;
  padding: 100px 0;
  .container {
    display: flex;
    align-items: center;
    gap: 50px;
    @media screen and (max-width: 1500px) {
      gap: 0;
    }
    @media screen and (max-width: 1300px) {
      flex-direction: column;
    }
  }
  &-left {
    position: relative;
    width: 856px;
    flex-shrink: 0;
    min-height: 465px;
    @media screen and (max-width: 1500px) {
      width: 840px;
    }
    h1 {
      position: absolute;
      top: 97px;
      left: 181px;
      color: #303030;
      font-size: 50px;
      font-weight: 900;
      line-height: 145.4%; /* 72.7px */
      letter-spacing: 5.75px;
      max-width: 444px;
      @media screen and (max-width: 1500px) {
        font-size: 45px;
        left: 160px;
      }
      @media screen and (max-width: 1300px) {
        font-size: 40px;
        line-height: 120%;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    p {
      position: absolute;
      top: 257px;
      left: 181px;
      max-width: 618px;
      color: #000;
      font-weight: 500;
      line-height: 145.4%;
      letter-spacing: 0.32px;
      @media screen and (max-width: 1500px) {
        left: 160px;
        top: 245px;
      }
      @media screen and (max-width: 1300px) {
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }
      @media screen and (max-width: 600px) {
        max-width: 350px;
      }
    }
  }
  &-right {
    position: relative;
    max-width: 700px;
    @media screen and (max-width: 1300px) {
      margin-top: -150px;
    }
    @media screen and (max-width: 600px) {
      margin-top: -100px;
    }
  }
}

.vision {
  background-image: url("./assets/img/bg-vision.svg");
  background-size: cover;
  padding: 100px 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    @media screen and (max-width: 1300px) {
      flex-direction: column-reverse;
      gap: 0;
    }
  }
  &-left {
    max-width: 934px;
    @media screen and (max-width: 1300px) {
      max-width: 700px;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 776px;
    &-item {
      p {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 600;
        line-height: 158.9%;
        @media screen and (max-width: 1300px) {
          text-align: center;
        }
      }
      &-1 {
        div {
          max-width: 175px;
          @media screen and (max-width: 1300px) {
            max-width: 150px;
            margin: 0 auto;
          }
        }
      }
      &-2 {
        div {
          max-width: 328px;
          @media screen and (max-width: 1300px) {
            margin: 0 auto;
            max-width: 300px;
          }
        }
      }
      &-3 {
        div {
          max-width: 385px;
          @media screen and (max-width: 1300px) {
            margin: 0 auto;
            max-width: 360px;
          }
        }
      }
    }
  }
}

.shibanomics {
  background-color: #faab35;
  background-image: url("./assets/img/bg-main.svg");
  background-size: cover;
  padding: 100px 0;
  &-price {
    display: flex;
    gap: 15px;
    @media screen and (max-width: 1300px) {
      justify-content: center;
    }
    &-icon {
      width: 82px;
    }
    p {
      display: flex;
      flex-direction: column;
      span {
        color: #fff;
        &:first-child {
          font-size: 30px;
          font-weight: 900;
          line-height: 145.4%; /* 43.62px */
          letter-spacing: 0.9px;
        }
        &:last-child {
          font-size: 50px;
          font-weight: 900;
          line-height: 145.4%; /* 72.7px */
          letter-spacing: 1.5px;
        }
      }
    }
  }
  &-info {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    @media screen and (max-width: 1300px) {
      flex-direction: column;
      align-items: center;
    }
    &-left {
      width: 70%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      border-radius: 20px;
      background-color: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      @media screen and (max-width: 1000px) {
        width: 100%;
      }
      @media screen and (max-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
        .border-mb {
          border-bottom: 1px dashed #000;
        }
        .not-border-mb {
          border-right: 0 !important;
        }
      }
      li {
        text-align: center;
        font-size: 18px;
        font-weight: 900;
        line-height: 145.4%; /* 34.896px */
        letter-spacing: 0.72px;
        &:not(:last-child) {
          h3 {
            border-right: 1px dashed #000;
          }
          p {
            border-right: 1px dashed #000;
          }
        }
        h3 {
          padding: 22px 0;
          border-bottom: 1px dashed #000;
          color: #eba900;
        }
        p {
          padding: 80px 0;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      li {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;
      }
    }
  }
  &-ecosystem {
    margin-top: 100px;
    &-title {
      color: #fff;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Joti One;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-box {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 24px;
      &-item {
        background-color: #fff;
        display: flex;
        align-items: center;
        border-radius: 14px;
        gap: 50px;
        @media screen and (max-width: 1000px) {
          padding: 20px 0;
          gap: 20px;
        }
        @media screen and (max-width: 700px) {
          flex-direction: column;
          gap: 40px;
          padding: 40px 20px !important;
          text-align: center;
        }
        &-img {
          display: flex;
        }
        p {
          color: #2f2f2f;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 159.9%; /* 31.98px */
          @media screen and (max-width: 1000px) {
            font-size: 16px;
            line-height: 140%;
          }
          @media screen and (max-width: 700px) {
            margin-top: 20px;
          }
        }
        .shibanomics-ecosystem-box-item-right-title {
          margin-bottom: 20px;
        }
        &-1 {
          padding-right: 50px;
          @media screen and (max-width: 1000px) {
            padding-right: 20px;
          }
          .shibanomics-ecosystem-box-item-img {
            max-width: 250px;
            @media screen and (max-width: 700px) {
              max-width: 200px;
            }
          }
          .shibanomics-ecosystem-box-item-right-title {
            max-width: 650px;
            @media screen and (max-width: 700px) {
              margin: 0 auto;
            }
          }
        }
        &-2 {
          justify-content: flex-end;
          padding-left: 50px;
          @media screen and (max-width: 1000px) {
            padding-left: 20px;
          }
          .shibanomics-ecosystem-box-item-img {
            max-width: 300px;
            @media screen and (max-width: 700px) {
              max-width: 200px;
            }
          }
          .shibanomics-ecosystem-box-item-right-title {
            max-width: 200px;
            @media screen and (max-width: 1000px) {
              max-width: 160px;
            }
            @media screen and (max-width: 700px) {
              margin: 0 auto;
              max-width: 120px;
            }
          }
        }
        &-3 {
          margin-bottom: 50px;
          padding-right: 50px;
          @media screen and (max-width: 1000px) {
            padding-right: 20px;
          }
          .shibanomics-ecosystem-box-item-img {
            max-width: 200px;
          }
          .shibanomics-ecosystem-box-item-right-title {
            max-width: 250px;
            @media screen and (max-width: 1000px) {
              max-width: 200px;
            }
            @media screen and (max-width: 700px) {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

.roadmap {
  background-image: url("./assets/img/bg-vision.svg");
  background-size: cover;
  padding: 100px 0;
  &-box {
    padding-top: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 50px;
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
    }
    &-item {
      &-title {
        width: 200px;
        margin: 0 auto;
      }
      &-list {
        margin-top: 30px;
        padding: 40px 15px;
        background-color: #f4bc4c;
        border-bottom: 13px solid #000;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        li {
          display: flex;
          align-items: center;
          gap: 10px;
          span {
            color: #2f2f2f;
            font-weight: 700;
            // font-size: 18px;
          }
        }
        &-icon {
          width: 35px;
        }
      }
    }
    &-footer {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      gap: 80px;
      @media screen and (max-width: 700px) {
        gap: 20px;
      }
    }
  }
}

.footer {
  padding: 50px 0 30px;
  border-top: 10px solid #eba900;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  &-buy {
    max-width: 250px;
  }
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-social {
      display: flex;
      gap: 30px;
      justify-content: flex-end;
      margin-bottom: 50px;
      @media screen and (max-width: 1000px) {
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        padding-top: 30px;
        border-top: 1px solid #000;
      }
    }
  }
}
